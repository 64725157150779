import {useGlobalState} from "../../state/GlobalState";
import "../../styles/RebillingTooltip.scss";
import {getAvailableVehicleLines} from "../../service/OfferingService";
import {useEffect, useState} from "react";
import {getSkin, Skin} from "../../service/SkinService";
import Select from "react-select";
import {useTranslation} from "../../utils/Translations";

interface formattedVehicleLine {
  value: string;
  label: string;
}

export function VehicleLineSelector(props: {salesCode: string}) {
  const [vehicleLine, setVehicleLine] = useGlobalState("vehicleLine");
  const [vehicleLineOptions, setVehicleLineOptions] = useState(
    [] as formattedVehicleLine[]
  );
  const translations = useTranslation([
    "selectVehicleLinePlaceholderText",
    "noOptionsSelector",
    "selectVehicleLineWarningMessage",
    "NoVehicleForSalesCode",
    "noAvailableVehicleLines",
  ]);
  const isSalesCodeSelected = () => props.salesCode !== "";

  useEffect(() => {
    if (isSalesCodeSelected()) {
      getAvailableVehicleLines(props.salesCode).then((vehicleLines) => {
        setVehicleLine("");
        setVehicleLineOptions(formatVehicleLineOptions(vehicleLines));

        if (vehicleLines.length === 1 && isSalesCodeSelected()) {
          setVehicleLine(vehicleLines[0]);
        }
      });
    }
  }, [props.salesCode]); // eslint-disable-line react-hooks/exhaustive-deps

  function formatVehicleLineOptions(vehicleLineOptions: string[]) {
    return vehicleLineOptions?.map((element: string): formattedVehicleLine => {
      return {
        value: element,
        label: element,
      };
    });
  }

  const getVehicleLineSelectorPlaceHolder = () => {
    if (vehicleLineOptions.length === 1 && isSalesCodeSelected()) {
      return vehicleLineOptions[0].label;
    } else if (vehicleLineOptions.length === 0 && isSalesCodeSelected()) {
      return translations["noAvailableVehicleLines"];
    }
    return translations["selectVehicleLinePlaceholderText"];
  };

  const getVehicleLineSelectorIsEnabledStatus = () => {
    return isSalesCodeSelected() && vehicleLineOptions.length > 1;
  };

  let vehicleLinesAvailableForSaleCode =
    isSalesCodeSelected() && vehicleLineOptions.length === 0;

  return (
    <div
      className={"fmc-top-banner--warning salesCodeContainer"}
      data-testid={"vehicle-line-container"}>
      <div>
        <form data-testid="vls-select" style={{minWidth: "250px"}}>
          <label className="hidden" htmlFor="vehicleLineOptions">
            Vehicle Lines
          </label>
          <Select
            value={
              vehicleLine
                ? {value: vehicleLine, label: vehicleLine.replace("MKX-", "")}
                : null
            }
            className="sales-code-dropdown"
            options={vehicleLineOptions}
            name="vehicleLineOptions"
            inputId="vehicleLineOptions"
            id="vehicleLineSelector"
            placeholder={getVehicleLineSelectorPlaceHolder()}
            onChange={(event: any) => setVehicleLine(event.value)}
            isDisabled={!getVehicleLineSelectorIsEnabledStatus()}
            noOptionsMessage={() => translations["noOptionsSelector"]}
          />
        </form>
      </div>
      {vehicleLine === "" && (
        <div className={"salesCodeSelectWarning"}>
          <div
            className={
              getSkin() === Skin.LPIVW ? "lincoln-warning-icon" : "warning-icon"
            }>
            <span
              className={
                getSkin() === Skin.LPIVW
                  ? "fds-icon fds-icon--22 fds-font--ford-icons__warning-filled"
                  : "fds-icon fds-icon--22 fds-font--ford-icons__warning-filled"
              }></span>
            <span>
              &nbsp;{" "}
              {vehicleLinesAvailableForSaleCode
                ? translations["NoVehicleForSalesCode"]
                : translations["selectVehicleLineWarningMessage"]}{" "}
              &nbsp;
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
