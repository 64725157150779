import {useEffect, useState} from "react";
import {getPublicMessage} from "./PublicMessageService";
import {useGlobalState} from "../../state/GlobalState";
import "../../styles/PublicMessage.scss";
import {messageDisplay, MessageDisplay} from "./MessageDisplay";

export function PublicMessage() {
  const [publicMessage, setPublicMessage] = useState<string>("");
  const [nationalMessage, setNationalMessage] = useState<messageDisplay>({
    message: "",
    id: "",
    region: null,
  });
  const [hasAdminPermissions] = useGlobalState("hasAdminPermissions");

  useEffect(() => {
    getPublicMessage().then((response) => {
      if (response.data) {
        setPublicMessage(response.data.message);
        setNationalMessage(response.data);
      }
    });
  }, []);
  return (
    <div>
      {hasAdminPermissions && (
        <MessageDisplay
          region={nationalMessage.region}
          message={nationalMessage.message}
          id={nationalMessage.id}
        />
      )}
      {!hasAdminPermissions && publicMessage && (
        <MessageDisplay
          region={nationalMessage.region}
          message={nationalMessage.message}
          id={nationalMessage.id}
        />
      )}
    </div>
  );
}
