import {getHttpClient} from "./API";
import {ApiUrls} from "../enums/ApiUrls";
import {RegionPermission} from "./RegionService.test";

export async function getRegionPermission(
  cdsid: string
): Promise<RegionPermission> {
  return (
    await getHttpClient().get(ApiUrls.regionPermission + `?cdsid=${cdsid}`)
  ).data;
}
