import Select from "react-select";
import {useEffect, useState} from "react";
import {
  getCompletedAndActiveOfferings,
  Offering,
} from "../../service/OfferingService";
import {AdminPageReports} from "../../components/admin/AdminPageReports";
import {getSkin, Skin} from "../../service/SkinService";
import {DownloadModal} from "../../components/downloadModal/DownloadModal";
import {PermissionsAdminTool} from "../../components/admin/PermissionsAdminTool";
import styles from "../../styles/AdminPage.module.scss";
import {AdminGuard} from "../../components/admin/AdminGuard";
import {ModelERebillingReport} from "../../components/admin/ModelERebillingReport";
import {TextTranslation, useTranslation} from "../../utils/Translations";
import {ModelEAvailableVehicleReport} from "../../components/admin/ModelEAvailableVehicleReport";
import {RebillOfferingAdminTool} from "../../components/admin/RebillOfferingAdminTool";
import {useGlobalState} from "../../state/GlobalState";

export function AdminPage() {
  const [offerings, setOfferings] = useState([]);
  const [selectedOffering, setSelectedOffering] = useState({
    id: "",
    dateRange: "",
  });

  const translations = useTranslation(["selectOfferingPeriodPlaceholderText"]);

  const [featureFlags] = useGlobalState("featureFlags");
  const featureFlag = featureFlags.includes("rebillingEnabled");

  useEffect(() => {
    getCompletedAndActiveOfferings().then((offeringData) => {
      setOfferings(
        offeringData.map((offerings: Offering) => {
          return {label: offerings.dateRange, value: offerings._id};
        })
      );
    });
  }, []);

  /**
   * Can delete after Rebilling automation is complete
   */

  return (
    <AdminGuard>
      <>
        <h1
          className={
            getSkin() === Skin.LPIVW
              ? styles.lincolnAdminPageHeader
              : styles.adminPageHeader
          }>
          <TextTranslation textId={"adminDashboard"} />
        </h1>
        <div className={styles.adminDashboardContainer}>
          <div
            className={`${styles.adminElementContainers} ${styles.adminDownloadReports}`}>
            {
              <>
                <h2 className={styles.permissionsHeader}>
                  <TextTranslation textId={"adminDownloadReports"} />
                </h2>
                <p className={styles.adminText}>
                  <TextTranslation textId={"adminReportDescription"} />
                </p>

                <table className={styles.adminBar}>
                  <thead>
                    <tr className={styles.adminBarTop}>
                      <th>
                        <TextTranslation textId={"adminOfferPeriod"} />{" "}
                      </th>
                      <th>
                        <TextTranslation textId={"adminDownloadReportRow"} />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Select
                          aria-label={"Offerings"}
                          className={styles.select}
                          placeholder={
                            translations["selectOfferingPeriodPlaceholderText"]
                          }
                          options={offerings}
                          onChange={(event: any) =>
                            setSelectedOffering({
                              id: event.value,
                              dateRange: event.label,
                            })
                          }
                        />
                      </td>
                      <td>
                        <AdminPageReports offeringId={selectedOffering.id} />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <DownloadModal />
              </>
            }
          </div>
          <div className={styles.adminElementContainers}>
            <PermissionsAdminTool />
          </div>
          {featureFlag && (
            <div className={styles.adminElementContainers}>
              <RebillOfferingAdminTool />
            </div>
          )}
          {getSkin() === Skin.MEW && (
            <>
              <div className={styles.rebillingReportContainer}>
                <h2 className={styles.reportHeader}>
                  Rebilled Vehicle History Report
                </h2>
                <ModelERebillingReport />
              </div>
              <div className={styles.rebillingReportContainer}>
                <h2 className={styles.reportHeader}>
                  Available Vehicle Report
                </h2>
                <ModelEAvailableVehicleReport />
              </div>
            </>
          )}
        </div>
      </>
    </AdminGuard>
  );
}
