import {
  getSalesCodesByBrand,
  SalesCodeType,
} from "../../service/SalesCodeService";
import "react-dropdown/style.css";
import Select from "react-select";
import "../../styles/SalesCodeSelector.scss";
import {useEffect, useState} from "react";
import {Dealer, getDealerInformation} from "../../service/DealerService";
import {getSkin, Skin} from "../../service/SkinService";
import {getBrand} from "../../service/BrandService";
import {TextTranslation, useTranslation} from "../../utils/Translations";

export function SalesCodeDropDownMenu(props: {
  salesCodeOptions: SalesCodeType[];
  handleChange: (event: any) => void;
  salesCode: string;
}) {
  const translations = useTranslation(["selectSalesCodePlaceholderText"]);

  return (
    <Select
      className=" sales-code-dropdown"
      options={props.salesCodeOptions}
      aria-label={"sales code options"}
      name="salesCodesOptions"
      inputId="salesCodesOptions"
      onChange={props.handleChange}
      placeholder={translations["selectSalesCodePlaceholderText"]}
      value={
        props.salesCode
          ? {value: props.salesCode, label: props.salesCode}
          : null
      }
    />
  );
}

export const SalesCodeSelector = ({
  dealerInformationChangeHandler,
  salesCode,
  salesCodeChangeEventHandler,
}: {
  salesCode: string;
  salesCodeChangeEventHandler: (event: any) => void;
  dealerInformationChangeHandler?: (newDealerInformation: Dealer) => void;
}) => {
  const [salesCodeOptions, setSalesCodeOptions] = useState<SalesCodeType[]>([]);
  const [dealerName, setDealerName] = useState<string>("");

  const skin = getSkin();

  /* eslint-disable */
  useEffect(() => {
    getSalesCodesByBrand(getBrand(skin)).then((salesCodes) => {
      setSalesCodeOptions(salesCodes);
      if (salesCodes.length === 1) {
        salesCodeChangeEventHandler({value: salesCodes[0].value});
      }
    });
  }, []);
  /* eslint-enable */

  useEffect(() => {
    if (salesCode !== "") {
      getDealerInformation(salesCode).then((data: Dealer) => {
        setDealerName(data.dealerName);
        dealerInformationChangeHandler && dealerInformationChangeHandler(data);
      });
    }
  }, [salesCode, dealerInformationChangeHandler]);

  const displayWarningMessage = () => {
    return salesCode === "";
  };

  return (
    <div
      className={"fmc-top-banner--warning salesCodeContainer"}
      data-testid={"sales-code-container"}>
      <div>
        <form data-testid={"form"} style={{minWidth: "250px"}}>
          <label className="hidden" htmlFor="salesCodesOptions">
            Sales Codes
          </label>
          <SalesCodeDropDownMenu
            salesCodeOptions={salesCodeOptions}
            handleChange={salesCodeChangeEventHandler}
            salesCode={salesCode}
          />
        </form>
      </div>
      {displayWarningMessage() ? (
        <div
          data-testid={"salesCodeSelectWarning"}
          className={"salesCodeSelectWarning"}>
          <div
            className={
              getSkin() === Skin.LPIVW ? "lincoln-warning-icon" : "warning-icon"
            }>
            <span
              className={
                getSkin() === Skin.LPIVW
                  ? "lincoln-warning-icon fds-icon fds-icon--22 fds-font--ford-icons__warning-filled"
                  : "warning-icon fds-icon fds-icon--22 fds-font--ford-icons__warning-filled"
              }></span>
            <span>
              &nbsp;{" "}
              <TextTranslation textId={"selectSalesCodeWarningMessage"} />{" "}
              &nbsp;
            </span>
          </div>
        </div>
      ) : (
        <p data-testid={"dealer-name"}>{dealerName}</p>
      )}
    </div>
  );
};
