import React from "react";

export function Maintenance() {
  return (
    <div style={{padding: "3rem"}}>
      <h2>Portal Undergoing Maintenance</h2>
      <div
        className={"public-message-inner-container"}
        style={{padding: "3rem"}}>
        <div className="public-message-text">
          The portal is currently unavailable and will return to operational
          status in about 4 hours. We apologize for the inconvenience.
        </div>
      </div>
    </div>
  );
}
