import "../../../styles/Home.scss";
import "../../../styles/PublicMessage.scss";
import {ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import * as React from "react";
import {useGlobalState} from "../../../state/GlobalState";
import {getIcon, IconName} from "../../../service/iconFinderService";
import {TextTranslation} from "../../../utils/Translations";

export function PublicMessageMenuItem(props: {
  setPublicMessageVisible: (visible: boolean) => void;
}) {
  const [hasAdminPermissions] = useGlobalState("hasAdminPermissions");

  if (hasAdminPermissions) {
    return (
      <>
        <MenuItem
          data-testid="public-message-menu-item"
          onClick={() => {
            props.setPublicMessageVisible(true);
          }}>
          <ListItemIcon>
            <img
              src={getIcon(IconName.PublicMessage)}
              className="menu-button-icons"
              alt={"urgent communication menu button"}
            />
          </ListItemIcon>
          <ListItemText>
            <TextTranslation textId={"nationalMessageModalHeader"} />
          </ListItemText>
        </MenuItem>
      </>
    );
  } else {
    return <></>;
  }
}
