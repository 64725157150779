import React, {useEffect} from "react";
import styles from "../../styles/OrderGrid.module.scss";
import {AcceptReject} from "./AcceptReject";
import historyIcon from "../../images/historyIcon.png";
import ReactTooltip from "react-tooltip";
import {useGlobalState} from "../../state/GlobalState";
import {Table} from "antd";
import {
  collapseIcon,
  expandIcon,
  OrderGridExpandedRowDetails,
} from "./OrderGridExpandedRowDetails";
import {FormattedOrder, Order, orderToFormattedOrder} from "../../types/order";
import {ReallocateButton} from "./ReallocateButton";
import {TableColumn} from "../../models/TableColumn";
import {getOrdersFor} from "../../service/OrderService";
import {
  getAllDescriptionsByVehicleLineAndCountry,
  searchDescription,
} from "../../service/ContentService";
import {getCountryLocale} from "../modelE/Utils";
import {TextTranslation} from "../../utils/Translations";
import {getCurrentCountry} from "../../service/CountryService";

export function OrderGrid() {
  const [orderGridData, setOrderGridData] = useGlobalState("orderGridData");
  const [salesCode] = useGlobalState("salesCode");
  const [submissionMade] = useGlobalState("submissionMade");
  const [offeringWindowOngoing] = useGlobalState("offeringWindowOngoing");
  const [currentLanguage] = useGlobalState("language");
  const [reallocationPeriodOngoing] = useGlobalState(
    "reallocationPeriodOngoing"
  );
  const [hasReadAllPermissions] = useGlobalState("hasReadAllPermissions");
  const [hasAdminPermissions] = useGlobalState("hasAdminPermissions");
  const [vehicleLine] = useGlobalState("vehicleLine");
  const [wersDescriptions] = useGlobalState("wersDescriptions");
  const [currentCountry] = useGlobalState("country");
  const [featureFlags] = useGlobalState("featureFlags");
  const fieldOpsCanSeeSelectionFlag =
    featureFlags.includes("delegateSubmitFlag");

  function getEmptyText() {
    if (!offeringWindowOngoing && !reallocationPeriodOngoing)
      return <TextTranslation textId={"dealerOrderWindowClosed"} />;
    if (!offeringWindowOngoing && reallocationPeriodOngoing)
      return <TextTranslation textId={"DuringReallocationPeriod"} />;
    else if (salesCode === "")
      return (
        <div>
          <p>
            <TextTranslation textId={"orderGridDefaultMessage"} />
          </p>
          <br />
          <p>
            <TextTranslation textId={"orderGridHelpMessage"} />
          </p>
        </div>
      );
    else if (orderGridData.length === 0)
      return <TextTranslation textId={"NoVehicleForSalesCode"} />;
    else if (vehicleLine === "")
      return <TextTranslation textId={"SelectVehicleLine"} />;
    else return <TextTranslation textId={"NoCurrentOfferings"} />;
  }

  function getFormattedSelection(selection: string): React.ReactElement {
    if (selection.toLowerCase() === "accepted") {
      return <TextTranslation textId={"acceptedLabel"} />;
    } else if (selection.toLowerCase() === "rejected") {
      return <TextTranslation textId={"rejectedLabel"} />;
    } else {
      return <></>;
    }
  }

  function getOrdersBySalesCode(userSalesCodes: string | null) {
    getOrdersFor(userSalesCodes)
      .then((response: {data: Order[]}) => {
        const formattedOrders = response.data.map(orderToFormattedOrder);
        setOrderGridData(formattedOrders);
      })
      .catch((reason: any) => {
        setOrderGridData([{vin: reason, key: reason} as FormattedOrder]);
      });
  }

  useEffect(() => {
    if (salesCode !== null && salesCode !== "") getCurrentCountry(salesCode);
  }, [salesCode]);

  useEffect(() => {
    if (
      vehicleLine !== null &&
      vehicleLine !== "" &&
      currentCountry !== null &&
      currentCountry !== ""
    )
      getAllDescriptionsByVehicleLineAndCountry(vehicleLine, currentCountry);
  }, [vehicleLine, currentCountry]);

  function buildOrderGridColumns() {
    let orderGridColumns = [
      new TableColumn({
        title: <TextTranslation textId={"vinLabel"} />,
        key: "vin",
      }),
      new TableColumn({
        title: <TextTranslation textId={"bodyLabel"} />,
        key: "body",
        render: (data: FormattedOrder) => (
          <>
            {searchDescription(
              currentLanguage + "_" + currentCountry,
              data.body,
              wersDescriptions
            )}
          </>
        ),
      }),
      new TableColumn({
        title: <TextTranslation textId={"codeLabel"} />,
        key: "code",
        render: (data: FormattedOrder) => (
          <>
            {searchDescription(
              currentLanguage + "_" + currentCountry,
              data.code,
              wersDescriptions
            )}
          </>
        ),
      }),
      new TableColumn({
        title: <TextTranslation textId={"engineLabel"} />,
        key: "engine",
        render: (data: FormattedOrder) => (
          <>
            {searchDescription(
              currentLanguage + "_" + currentCountry,
              data.engine,
              wersDescriptions
            )}
          </>
        ),
      }),
      new TableColumn({
        title: <TextTranslation textId={"paintLabel"} />,
        key: "paint",
        render: (data: FormattedOrder) => (
          <>
            {searchDescription(
              currentLanguage + "_" + currentCountry,
              data.paint,
              wersDescriptions
            )}
          </>
        ),
      }),
      new TableColumn({
        title: <TextTranslation textId={"trimLabel"} />,
        key: "trim",
        render: (data: FormattedOrder) => (
          <>
            {searchDescription(
              currentLanguage + "_" + currentCountry,
              data.trim,
              wersDescriptions
            )}
          </>
        ),
      }),
      new TableColumn({
        title: <TextTranslation textId={"statusLabel"} />,
        key: "formattedSelection",
        render: (data: any) => (
          <div className={styles.orderStatus}>
            {getFormattedSelection(data.formattedSelection)}
            {data.formattedSelection ? getHistoryIcon(data) : <></>}
          </div>
        ),
      }),
    ];

    addDealerSelectionColumn(orderGridColumns);
    addReallocateColumn(orderGridColumns);

    return orderGridColumns;
  }

  function getHistoryIcon(data: any) {
    return (
      <>
        <ReactTooltip
          place={"bottom"}
          effect={"solid"}
          multiline={true}
          id={"history-tooltip"}>
          <TextTranslation textId={"lastModifiedBy"} />
          {data.lastModifiedBy}
          <TextTranslation textId={"onLabel"} />
          {new Date(data.lastModifiedSelectionDate).toLocaleString(
            [getCountryLocale(currentLanguage)],
            {
              month: "short",
              day: "2-digit",
            }
          )}
        </ReactTooltip>
        <span
          data-testid={"history-tooltip-span"}
          data-tip="data-tip-to-override"
          data-for={"history-tooltip"}>
          <img
            data-testid={"history-icon"}
            src={historyIcon}
            alt={""}
            className={styles.historyIcon}
          />
        </span>
      </>
    );
  }

  function addReallocateColumn(orderGridColumns: Array<any>) {
    if (hasAdminPermissions || hasReadAllPermissions) {
      orderGridColumns.push(getReallocateColumn());
    }
  }

  function addDealerSelectionColumn(orderGridColumns: Array<any>) {
    if (fieldOpsCanSeeSelectionFlag) {
      let indexOfColumnToAdd = orderGridColumns.length - 1;
      orderGridColumns.splice(
        indexOfColumnToAdd,
        0,
        getDealerSelectionColumn()
      );
    } else {
      let isDealer = hasReadAllPermissions === false;
      if (isDealer) {
        let indexOfColumnToAdd = orderGridColumns.length - 1;
        orderGridColumns.splice(
          indexOfColumnToAdd,
          0,
          getDealerSelectionColumn()
        );
      }
    }
  }

  function getDealerSelectionColumn() {
    return new TableColumn({
      title: <TextTranslation textId={"dealerSelection"} />,
      key: "acceptReject",
      render: (data: any) => <AcceptReject order={data} />,
    });
  }

  function getReallocateColumn() {
    return new TableColumn({
      title: <TextTranslation textId={"reallocateLabel"} />,
      key: "Reallocate",
      render: (data: any) => <ReallocateButton vin={data.vin} />,
    });
  }

  useEffect(() => {
    if (salesCode === null || salesCode === "") return;
    getOrdersBySalesCode(salesCode);
  }, [salesCode, submissionMade]); // eslint-disable-line react-hooks/exhaustive-deps

  function filterByVehicleLine() {
    if (vehicleLine) {
      return orderGridData.filter(
        (order) => order?.vehicleLine === vehicleLine
      );
    }
    return;
  }

  return (
    <div data-testid="order-grid" className={styles.orderGrid}>
      <Table
        className="fmc-table"
        columns={buildOrderGridColumns()}
        dataSource={filterByVehicleLine()}
        pagination={false}
        locale={{emptyText: getEmptyText()}}
        expandable={{
          expandIcon: ({expanded, onExpand, record}) => (
            <p
              id="expand-vehicle-details"
              data-testid="expand-data"
              onClick={(e) => onExpand(record, e)}>
              {expanded ? collapseIcon : expandIcon}
            </p>
          ),
          expandedRowRender: (record) => (
            <OrderGridExpandedRowDetails order={record} />
          ),
        }}
      />
    </div>
  );
}
