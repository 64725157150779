import {Route, Routes} from "react-router-dom";
import {Home} from "../pages/home/Home";
import {Login} from "../pages/login/Login";
import {OauthCallback} from "../components/login/OauthCallback";
import {OrderHistory} from "../pages/orderHIstory/OrderHistory";
import {FAQ} from "../pages/faq/FAQ";
import {AdminPage} from "../pages/admin/AdminPage";
import React from "react";
import {PageNotFound} from "../pages/PageNotFound";
import {DevPage} from "../pages/dev/DevPage";
import {useGlobalState} from "../state/GlobalState";
import {Maintenance} from "../pages/MaintenancePage";

const pages = {
  home: <Home />,
  history: <OrderHistory />,
  faq: <FAQ />,
};

const maintenancePages = {
  home: <Maintenance />,
  history: <Maintenance />,
  faq: <Maintenance />,
};

export function ModelEOfferingRoutes() {
  const [featureFlags] = useGlobalState("featureFlags");
  const pagesToRender = featureFlags.includes("maintenanceEnabled")
    ? maintenancePages
    : pages;

  return (
    <>
      <Routes>
        <Route path="/" element={pagesToRender.home} />
        <Route path="/login" element={<Login />} />
        <Route path="/oauth-callback" element={<OauthCallback />} />
        <Route path="/history" element={pagesToRender.history} />
        <Route path="/faq" element={pagesToRender.faq} />
        <Route path="/dev" element={<DevPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}
