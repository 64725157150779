import {useGlobalState} from "../../state/GlobalState";
import React, {useEffect, useState} from "react";
import {getSkin, Skin} from "../../service/SkinService";
import {AdminTools} from "../../components/admin/AdminTools";
import {AcceptancePendingModal} from "../../components/home/AcceptancePendingModal";
import {SalesCodeSelector} from "../../components/orderGridFilters/SalesCodeSelector";
import {VehicleLineSelector} from "../../components/orderGridFilters/VehicleLineSelector";
import {PublicMessage} from "../../components/publicMessage/PublicMessage";
import {ReallocationGuide} from "../../components/reallocation/ReallocationGuide";
import {OrderGrid} from "../../components/orderGrid/OrderGrid";
import {SubmitOfferings} from "../../components/vehicleSubmission/SubmitOfferings";
import "../../styles/Header.scss";
import {PortalTitle} from "../../components/home/PortalTitle";
import {ErrorModal} from "../../components/errorModal/ErrorModal";
import {CvwDisclaimer} from "../../components/cvwDisclaimer/CvwDisclaimer";
import {DownloadModal} from "../../components/downloadModal/DownloadModal";
import {Timer} from "../../components/timer/Timer";
import {OfferingStatus} from "../../components/orderGrid/OfferingStatus";
import {TextTranslation} from "../../utils/Translations";

export function RRContainer() {
  const [salesCode, setSalesCode] = useGlobalState("salesCode");
  const [dealerSelections] = useGlobalState("dealerSelectionList");
  const [toggledWarning, setToggledWarning] = useState(false);
  const skin = getSkin();
  const handleSalesCodeChange = (event: any) => {
    if (event.value !== salesCode && dealerSelections.length > 0) {
      setToggledWarning(true);
      setSalesCode(salesCode);
    } else {
      setSalesCode(event.value);
    }
  };

  useEffect(() => {});

  return (
    <>
      {skin === Skin.CVW && <CvwDisclaimer />}
      <PortalTitle showToolTip={true} />
      <AdminTools />
      {toggledWarning && (
        <AcceptancePendingModal
          isVisible={toggledWarning}
          setVisibility={setToggledWarning}
        />
      )}
      <div className="order-thing">
        <div className="timer-sales-code">
          <div className="dropdowns-container">
            <SalesCodeSelector
              salesCode={salesCode}
              salesCodeChangeEventHandler={handleSalesCodeChange}
            />
            <VehicleLineSelector salesCode={salesCode} />
          </div>
          <div className="timer-offering-status">
            <Timer />
            <OfferingStatus />
          </div>
        </div>
        <PublicMessage />
        <ReallocationGuide />
        <OrderGrid />
      </div>
      <div className={"selections-message"}>
        <TextTranslation textId={"lostSelections"} />
      </div>
      <div className={"bottom-submit-and-contact"}>
        <div className={"submitButton"}>
          <SubmitOfferings />
        </div>
      </div>
      <ErrorModal />
      <DownloadModal />
    </>
  );
}
