import {retrieveSalesCodesByCdsid} from "../../service/admin/AdminSalesCodesService";
import React, {useState} from "react";
import styles from "../../styles/AdminPage.module.scss";
import {TextTranslation, useTranslation} from "../../utils/Translations";
import ReactTooltip from "react-tooltip";
import {getRegionPermission} from "../../service/RegionService";

export function PermissionsAdminTool() {
  const [salesCodes, setSalesCodes] = useState<string[]>([]);
  const [cdsid, setCdsid] = useState<string>("");
  const [submittedCdsid, setSubmittedCdsid] = useState<string>("");
  const translations = useTranslation(["permissionCDSPlaceholder"]);
  const [reallocateRegions, setReallocateRegions] = useState<string[]>([]);
  const [delegateRegions, setDelegateRegions] = useState<string[]>([]);

  function retrieveUsersPermissions(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    retrieveSalesCodesByCdsid(cdsid).then((result) => {
      setSalesCodes(result);
      setSubmittedCdsid(cdsid);
    });

    getRegionPermission(cdsid).then((result) => {
      setReallocateRegions(result.reallocateRegions);
      setDelegateRegions(result.delegateRegions);
    });
  }

  return (
    <>
      <h2 className={styles.permissionsHeader}>
        <TextTranslation textId={"permissionLookup"} />
      </h2>
      <form
        onSubmit={retrieveUsersPermissions}
        className={styles.adminsPermissionsContainer}>
        <input
          placeholder={translations["permissionCDSPlaceholder"]}
          onChange={(event) => setCdsid(event.target.value)}
          className="fmc-input"
        />
        <>
          <ReactTooltip
            role={"tooltip"}
            place={"right"}
            effect={"solid"}
            disable={!(cdsid === "" || cdsid.length > 8)}
            id={"submit-tooltip"}>
            Please enter a valid CDSID
          </ReactTooltip>
          <span
            data-tip={"data-tip-to-override"}
            data-testid={"submit-button-tooltip-text"}
            data-for={"submit-tooltip"}>
            <button
              className="fmc-button"
              disabled={cdsid === "" || cdsid.length > 8}>
              <TextTranslation textId={"searchButton"} />
            </button>
          </span>
        </>
      </form>
      <div>
        {salesCodes && salesCodes.length > 0 && (
          <>
            <div className={styles.permissionSubHeader}>
              {submittedCdsid.toUpperCase() +
                " has read/submit access for these sales codes:"}{" "}
            </div>
            <div className={styles.salesCodeContainer}>
              {salesCodes.map((salesCode) => (
                <span className={styles.salesCode} key={salesCode}>
                  {salesCode}
                </span>
              ))}
            </div>
          </>
        )}
        {salesCodes && salesCodes.length === 0 && submittedCdsid !== "" && (
          <div className={styles.permissionSubHeader}>
            {"No sales codes found for " + submittedCdsid.toUpperCase()}
          </div>
        )}
        {!salesCodes && submittedCdsid !== "" && (
          <div className={styles.permissionSubHeader}>
            Failed to Retrieve Sales Codes
          </div>
        )}
      </div>
      <div>
        {reallocateRegions && reallocateRegions.length > 0 && (
          <>
            <div className={styles.permissionSubHeader}>
              {submittedCdsid.toUpperCase() +
                " has reallocation permissions for these regions:"}{" "}
            </div>
            <div className={styles.salesCodeContainer}>
              {reallocateRegions.map((region) => (
                <span className={styles.salesCode} key={region}>
                  {region}
                </span>
              ))}
            </div>
          </>
        )}
        {reallocateRegions &&
          reallocateRegions.length === 0 &&
          submittedCdsid !== "" && (
            <div className={styles.permissionSubHeader}>
              {"No reallocation permission found for " +
                submittedCdsid.toUpperCase()}
            </div>
          )}
      </div>
      <div>
        {delegateRegions && delegateRegions.length > 0 && (
          <>
            <div className={styles.permissionSubHeader}>
              {submittedCdsid.toUpperCase() +
                " has delegate permissions for these regions:"}
            </div>
            <div className={styles.salesCodeContainer}>
              {delegateRegions.map((region) => (
                <span className={styles.salesCode} key={region}>
                  {region}
                </span>
              ))}
            </div>
          </>
        )}
        {delegateRegions &&
          delegateRegions.length === 0 &&
          submittedCdsid !== "" && (
            <div className={styles.permissionSubHeader}>
              {"No delegate permission found for " +
                submittedCdsid.toUpperCase()}
            </div>
          )}
      </div>
    </>
  );
}
