import {Offering} from "../../service/OfferingService";
import {Country} from "../../enums/Country";
import styles from "../../styles/AdminPage.module.scss";
import React, {useState} from "react";
import {RebillingModal} from "./RebillingModal";
import {GetFormattedDate} from "../../utils/DateFormatter";
import {useGlobalState} from "../../state/GlobalState";

export function RebillingElement(props: {offering: Offering; key: any}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [currentLanguage] = useGlobalState("language");

  return (
    <div className={`${styles.adminRebilling}`}>
      <div>
        <strong>Offering Period: </strong>
        <span>{GetFormattedDate(props.offering, currentLanguage)}</span>
      </div>
      <div>
        <strong>Country: </strong>
        <span>{Country.toDisplayString(props.offering.country)}</span>
      </div>
      <div className={styles.rebillingCompleted}>
        {props.offering.isRebilled ? (
          <strong>Rebill Completed</strong>
        ) : (
          <button
            className={"fmc-button"}
            onClick={() => setModalVisible(true)}>
            Rebill
          </button>
        )}
      </div>

      {modalVisible && (
        <RebillingModal
          setModalVisibilityFalse={() => setModalVisible(false)}
          offering={props.offering}
        />
      )}
    </div>
  );
}
