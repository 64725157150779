import "../../styles/Home.scss";
import {Navigation} from "../navigation/Navigation";
import {ConfirmSubmissionModal} from "../vehicleSubmission/ConfirmSubmissionModal";
import * as React from "react";
import {Greetings} from "./Greetings";
import "../../styles/Header.scss";
import {HomeLink} from "./HomeLink";
import {useRecoilValue} from "recoil";
import ContactForm from "../../state/ContactForm";
import {LanguageMenu} from "../languageMenu/LanguageMenu";

export const FordDefaultHeader = (props: {logo: React.ReactElement}) => {
  const confirmationVisible = useRecoilValue(ContactForm);
  let logo = props.logo;
  return (
    <>
      <header className={"header"}>
        <a href={"/"} aria-label={"logo-link-to-home"}>
          {logo}
        </a>
        <span className={"header-content"}>
          <Greetings />
          <LanguageMenu />
          <div className={"home"}>
            <HomeLink />
          </div>
          <Navigation />
        </span>
      </header>
      <div>{confirmationVisible && <ConfirmSubmissionModal />}</div>
    </>
  );
};
