import {LincolnPortalTitle} from "../../components/lincoln/LincolnPortalTitle";
import React, {useState} from "react";
import {AdminTools} from "../../components/admin/AdminTools";
import {AcceptancePendingModal} from "../../components/home/AcceptancePendingModal";
import {SalesCodeSelector} from "../../components/orderGridFilters/SalesCodeSelector";
import {VehicleLineSelector} from "../../components/orderGridFilters/VehicleLineSelector";
import {OfferingStatus} from "../../components/orderGrid/OfferingStatus";
import {PublicMessage} from "../../components/publicMessage/PublicMessage";
import {ReallocationGuide} from "../../components/reallocation/ReallocationGuide";
import {OrderGrid} from "../../components/orderGrid/OrderGrid";
import {useGlobalState} from "../../state/GlobalState";
import {SubmitOfferings} from "../../components/vehicleSubmission/SubmitOfferings";
import {LincolnAllocationMethodology} from "../../components/lincoln/LincolnAllocationMethodology";
import {Timer} from "../../components/timer/Timer";
import {ErrorModal} from "../../components/errorModal/ErrorModal";
import "../../styles/lincoln/lincolnMain.scss";
import {LincolnCVWDisclaimer} from "../../components/lincoln/LincolnCVWDisclaimer";
import {DownloadModal} from "../../components/downloadModal/DownloadModal";
import {TextTranslation} from "../../utils/Translations";

export function LincolnContainer() {
  const [salesCode, setSalesCode] = useGlobalState("salesCode");
  const [dealerSelections] = useGlobalState("dealerSelectionList");
  const [toggledWarning, setToggledWarning] = useState(false);

  const handleSalesCodeChange = (event: any) => {
    if (event.value !== salesCode && dealerSelections.length > 0) {
      setToggledWarning(true);
      setSalesCode(salesCode);
    } else {
      setSalesCode(event.value);
    }
  };

  return (
    <>
      <div>
        <LincolnCVWDisclaimer />
        <LincolnPortalTitle />
        <AdminTools />
        {toggledWarning && (
          <AcceptancePendingModal
            isVisible={toggledWarning}
            setVisibility={setToggledWarning}
          />
        )}
        <div className="order-thing">
          <div className="timer-sales-code">
            <div className="dropdowns-container">
              <SalesCodeSelector
                salesCode={salesCode}
                salesCodeChangeEventHandler={handleSalesCodeChange}
              />
              <VehicleLineSelector salesCode={salesCode} />
            </div>
            <div className="timer-offering-status">
              <Timer />
              <OfferingStatus />
            </div>
          </div>
          <PublicMessage />
          <LincolnAllocationMethodology />
          <ReallocationGuide />
          <OrderGrid />
        </div>
        <div className={"selections-message"}>
          <TextTranslation textId={"approveButtonMessage"} />
        </div>
        <div className={"bottom-submit-and-contact"}>
          <div className={"submitButton"}>
            <SubmitOfferings />
          </div>
        </div>
        <ErrorModal />
        <DownloadModal />
      </div>
    </>
  );
}
