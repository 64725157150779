import {getSkin, Skin} from "./SkinService";
import SVRIcon from "../images/DropDownMenu/SVRIcon.svg";
import LincolnSVRIcon from "../images/DropDownMenu/Lincoln/lincolnSVRIcon.svg";
import OrderHistoryIcon from "../images/DropDownMenu/orderHistoryIcon.svg";
import LincolnOrderHistoryIcon from "../images/DropDownMenu/Lincoln/lincolnOrderHistoryIcon.svg";
import ContactUsIcon from "../images/DropDownMenu/contactUsIcon.svg";
import LincolnContactUsIcon from "../images/DropDownMenu/Lincoln/lincolnContactUsIcon.svg";
import FAQIcon from "../images/DropDownMenu/FAQIcon.svg";
import LincolnFAQIcon from "../images/DropDownMenu/Lincoln/lincolnFAQIcon.svg";
import WarningMessageIcon from "../images/DropDownMenu/warningMessageIcon.svg";
import LincolnWarningMessageIcon from "../images/DropDownMenu/Lincoln/lincolnWarningMessageIcon.svg";
import LogoutIcon from "../images/DropDownMenu/logoutIcon.svg";
import LincolnLogoutIcon from "../images/DropDownMenu/Lincoln/lincolnLogoutIcon.svg";
import LincolnAdminIcon from "../images/DropDownMenu/Lincoln/lincolnAdminIcon.svg";
import AdminIcon from "../images/DropDownMenu/adminIcon.svg";
import LincolnTrashCan from "../images/lincolnTrashCan.svg";
import TrashCan from "../images/trashCan.svg";
import LincolnMenuIcon from "../images/DropDownMenu/lincolnMenuIcon.svg";
import MenuIcon from "../images/DropDownMenu/menuIcon.svg";
import InfoIcon from "../images/infoTooltip.png";
import editIconGoogle from "../images/editIconGoogle.svg";
import trashCanGoogle from "../images/trashCanGoogle.svg";

export enum IconName {
  SVR,
  OrderHistory,
  ContactUs,
  FAQ,
  PublicMessage,
  Admin,
  Logout,
  TrashCan,
  MenuIcon,
  Info,
  editIconGoogle,
  trashCanGoogle,
}

export function getIcon(iconName: IconName) {
  const skin = getSkin();

  switch (true) {
    case iconName === IconName.SVR && skin === Skin.LPIVW:
      return LincolnSVRIcon;
    case iconName === IconName.SVR:
      return SVRIcon;
    case iconName === IconName.OrderHistory && skin === Skin.LPIVW:
      return LincolnOrderHistoryIcon;
    case iconName === IconName.OrderHistory:
      return OrderHistoryIcon;
    case iconName === IconName.ContactUs && skin === Skin.LPIVW:
      return LincolnContactUsIcon;
    case iconName === IconName.ContactUs:
      return ContactUsIcon;
    case iconName === IconName.FAQ && skin === Skin.LPIVW:
      return LincolnFAQIcon;
    case iconName === IconName.FAQ:
      return FAQIcon;
    case iconName === IconName.PublicMessage && skin === Skin.LPIVW:
      return LincolnWarningMessageIcon;
    case iconName === IconName.PublicMessage:
      return WarningMessageIcon;
    case iconName === IconName.Admin && skin === Skin.LPIVW:
      return LincolnAdminIcon;
    case iconName === IconName.Admin:
      return AdminIcon;
    case iconName === IconName.Logout && skin === Skin.LPIVW:
      return LincolnLogoutIcon;
    case iconName === IconName.Logout:
      return LogoutIcon;
    case iconName === IconName.TrashCan && skin === Skin.LPIVW:
      return LincolnTrashCan;
    case iconName === IconName.TrashCan:
      return TrashCan;
    case iconName === IconName.MenuIcon && skin === Skin.LPIVW:
      return LincolnMenuIcon;
    case iconName === IconName.MenuIcon:
      return MenuIcon;
    case iconName === IconName.Info:
      return InfoIcon;
    case iconName === IconName.editIconGoogle:
      return editIconGoogle;
    case iconName === IconName.trashCanGoogle:
      return trashCanGoogle;
  }
  return "";
}
