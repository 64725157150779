import "../../styles/MessageDisplay.scss";
import {useGlobalState} from "../../state/GlobalState";
import {PublicMessageModal} from "./PublicMessageModal";
import * as React from "react";
import {useState} from "react";
import {AreYouSureButton} from "../areYouSureButton/AreYouSureButton";
import {TextTranslation} from "../../utils/Translations";
import {getHttpClient} from "../../service/API";
import {ApiUrls} from "../../enums/ApiUrls";
import {getIcon, IconName} from "../../service/iconFinderService";
import {getSkin, Skin} from "../../service/SkinService";

export interface messageDisplay {
  message: string;
  id: string;
  region: string | null;
}

export function MessageDisplay(props: messageDisplay) {
  const [hasAdminPermissions] = useGlobalState("hasAdminPermissions");
  const [warningMessageModalVisible, setWarningMessageModalVisible] =
    useState(false);

  async function clearPublicMessage(): Promise<void> {
    return getHttpClient()
      .delete(ApiUrls.publicMessage)
      .then((response) => {
        if (response.status === 200) {
          console.log("deleted");
        } else {
          return Promise.reject(`Unexpected response: ${response.status}`);
        }
      });
  }

  function ModalComponents() {
    return (
      <>
        {warningMessageModalVisible && (
          <PublicMessageModal
            isVisible={warningMessageModalVisible}
            setVisibility={setWarningMessageModalVisible}
          />
        )}
      </>
    );
  }

  return (
    <>
      <ModalComponents />
      <div
        className={`message-container ${getSkin() === Skin.LPIVW ? "lincoln-message-container" : ""}`}
        data-testid="public-message">
        <div className={"message-display-container"}>
          <h3>
            {props.region ? (
              "Region Specific Message"
            ) : (
              <TextTranslation textId={"nationalMessageHeader"} />
            )}
          </h3>
          {hasAdminPermissions && (
            <button
              className={"message-display-buttons"}
              onClick={() => {
                setWarningMessageModalVisible(true);
              }}>
              <div>
                <img
                  style={{width: "12px", height: "12px"}}
                  src={getIcon(IconName.editIconGoogle)}
                  className="menu-button-icons"
                  alt={"SVR menu button"}
                />
              </div>
              <TextTranslation textId={"editMessageButton"} />
            </button>
          )}
          {hasAdminPermissions && props.message && (
            <AreYouSureButton
              confirmationQuestion={
                <TextTranslation textId={"deleteConfirmation"} />
              }
              confirmAction={clearPublicMessage}
              buttonLabel={
                <div className={"message-display-buttons"}>
                  <img
                    style={{
                      width: "12px",
                      height: "12px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    src={getIcon(IconName.trashCanGoogle)}
                    className="menu-button-icons"
                    alt={"Trash can"}
                  />
                  <div data-testid="delete-public-message">
                    <TextTranslation textId={"deleteMessageButton"} />
                  </div>
                </div>
              }
            />
          )}
        </div>
        <div className={"message-text"}>{props.message}</div>
      </div>
    </>
  );
}
