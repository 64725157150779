import {FaqElement, FAQKeyPair} from "./FaqElement";

const fordQuestionAndAnswerKeyPairs: FAQKeyPair[] = [
  {questionKey: "faqFordAcceptPortal", answerKey: "faqFordFIIAuthority"},
  {
    questionKey: "faqFordNoVehicleOfferings",
    answerKey: "faqFordDealershipEarned",
  },
  {
    questionKey: "faqFordAcceptedBeRebilled",
    answerKey: "faqFordAcceptedBeRebilledA",
  },
  {questionKey: "faqFordAmendOrders", answerKey: "faqFordAmendOrdersA1"},
  {questionKey: "faqFordITTroubles", answerKey: "faqFordITTroublesA"},
  {questionKey: "faqFordFindAccepted", answerKey: "faqFordFindAcceptedA1"},
];

export function FordFAQ() {
  return (
    <div className={"content-width"} data-testid={"fordFaq"}>
      {fordQuestionAndAnswerKeyPairs.map((questionAndAnswerKeyPair) => (
        <FaqElement
          faqKeyPair={questionAndAnswerKeyPair}
          key={
            questionAndAnswerKeyPair.questionKey +
            questionAndAnswerKeyPair.answerKey
          }
        />
      ))}
    </div>
  );
}
