import Modal from "react-modal";
import {useEffect, useState} from "react";
import {getHttpClient} from "../../service/API";
import {ApiUrls} from "../../enums/ApiUrls";
import {getSkin, Skin} from "../../service/SkinService";
import {TextTranslation} from "../../utils/Translations";
import {getPublicMessage} from "./PublicMessageService";

export function PublicMessageModal(props: {
  isVisible: boolean;
  setVisibility: (visible: boolean) => void;
}) {
  let publicModalIsOpen = props.isVisible;
  let setPublicModalIsOpen = props.setVisibility;
  let [publicMessage, setPublicMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const skin = getSkin();

  useEffect(() => {
    getPublicMessage().then((response) => {
      if (response.data) {
        setPublicMessage(response.data.message);
      }
    });
  }, []);

  function getHeader() {
    return (
      <>
        <div data-brand={skin === Skin.LPIVW ? "lincoln" : "ford"}>
          <button
            className="fmc-dialog__close fds-icon fds-font--ford-icons__clear close-button"
            data-testid="close-public-message-modal"
            aria-label={"Close public message modal"}
            onClick={() => {
              setPublicModalIsOpen(false);
            }}></button>
        </div>

        <div
          className={
            skin === Skin.LPIVW
              ? "lincoln-modal-header lpivw-font-family__proxima-nova"
              : "national-modal-header"
          }
          data-testid="public-message-header">
          {<TextTranslation textId={"nationalMessageModalHeader"} />}
        </div>
      </>
    );
  }

  function getHeaderSuccessfullySubmittedMessage() {
    return (
      <>
        <div data-brand={skin === Skin.LPIVW ? "lincoln" : "ford"}>
          <button
            className="fmc-dialog__close fds-icon fds-font--ford-icons__clear close-button"
            data-testid="close-public-message-modal"
            aria-label={"Close public message modal"}
            onClick={() => {
              setPublicModalIsOpen(false);
            }}></button>
        </div>

        <div
          className={
            skin === Skin.LPIVW
              ? "lincoln-modal-header lpivw-font-family__proxima-nova"
              : "national-modal-header"
          }
          data-testid="public-message-header">
          <TextTranslation textId={"nationalMessageSuccessHeader"} />
        </div>
      </>
    );
  }

  function getForm() {
    return (
      <div
        data-brand={skin === Skin.LPIVW ? "lincoln" : ""}
        className="public-message-modal-container">
        {getHeader()}
        <div className={"modal-information"}>
          <TextTranslation textId={"publicMessageInstructionText"} />
        </div>
        <label
          data-testid={"public-message-description"}
          id={"public-message-description"}
          className={
            skin === Skin.LPIVW
              ? "modal-description lincoln-public-message-modal-description lpivw-font-family__proxima-nova"
              : `modal-description`
          }>
          {<TextTranslation textId={"nationalMessageHeader"} />}
        </label>
        <textarea
          className={
            skin === Skin.LPIVW
              ? "modal-text lpivw-font-family__proxima-nova modal-text-padding"
              : "modal-text modal-text-padding"
          }
          rows={7}
          aria-describedby={"public-message-description"}
          defaultValue={publicMessage}
          onChange={(event) => {
            setPublicMessage(event.target.value);
          }}></textarea>
        {
          <div
            className="modal-text"
            style={{display: "flex", justifyContent: "end", gap: "16px"}}>
            <div className="fmc-mt-6">
              <button
                className={"overwritten-fds-outlined-button"}
                aria-label={"Cancel public message modal"}
                onClick={() => {
                  setPublicModalIsOpen(false);
                }}>
                <TextTranslation textId={"cancelButton"} />
              </button>
            </div>
            <div className="fmc-mt-6">
              <button
                className={"overwritten-fds-primary-button"}
                aria-label={"Submit public message modal"}
                onClick={() => {
                  getHttpClient()
                    .post(ApiUrls.publicMessage, {message: publicMessage})
                    .then(() => {
                      setIsSubmitted(true);
                    });
                }}>
                <TextTranslation textId={"publishMessageButton"} />
              </button>
            </div>
          </div>
        }
      </div>
    );
  }

  function getSuccessMessage() {
    return (
      <div
        data-brand={skin === Skin.LPIVW ? "lincoln" : ""}
        className="public-message-modal-container">
        {getHeaderSuccessfullySubmittedMessage()}
        <div className={"success-message-modal-header"}>
          <div>
            <TextTranslation textId={"nationalMessageSuccessText"} />
          </div>
        </div>
        <div className="fmc-mt-6 justify-close-button-right">
          <button
            className={"overwritten-fds-primary-button"}
            aria-label={"Close public message modal"}
            onClick={() => {
              setPublicModalIsOpen(false);
              window.location.reload();
            }}>
            <TextTranslation textId={"closeButtonLabel"} />
          </button>
        </div>
      </div>
    );
  }

  return (
    <Modal
      className={"modal-style"}
      isOpen={publicModalIsOpen}
      ariaHideApp={false}
      onRequestClose={() => {
        setPublicModalIsOpen(false);
      }}>
      {!isSubmitted && getForm()}
      {isSubmitted && getSuccessMessage()}
    </Modal>
  );
}
