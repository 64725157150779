import {Offering} from "../service/OfferingService";
import {getCountryLocale} from "../components/modelE/Utils";
import {Language} from "../enums/Language";

export function GetFormattedDate(offering: Offering, language: Language) {
  const countryLocale = getCountryLocale(language);

  const formattedStartTime = new Date(offering.startTime).toLocaleString(
    countryLocale,
    {
      month: "short",
      day: "numeric",
    }
  );

  const formattedEndTime = new Date(offering.endTime).toLocaleString(
    countryLocale,
    {
      month: "short",
      day: "numeric",
      year: "numeric",
    }
  );

  return `${formattedStartTime} - ${formattedEndTime}`;
}
