import {FaqElement, FAQKeyPair} from "./FaqElement";

const cvwQuestionAndAnswerKeyPairs: FAQKeyPair[] = [
  {questionKey: "faqFordAcceptPortal", answerKey: "faqFordFIIAuthority"},
  {
    questionKey: "faqFordNoVehicleOfferings",
    answerKey: "faqFordDealershipEarned",
  },
  {
    questionKey: "faqFordAcceptedBeRebilled",
    answerKey: "faqFordAcceptedBeRebilledA",
  },
  {questionKey: "faqFordAmendOrders", answerKey: "faqFordAmendOrdersA"},
  {questionKey: "faqFordITTroubles", answerKey: "faqFordITTroublesA"},
  {questionKey: "faqFordFindAccepted", answerKey: "faqFordFindAcceptedA"},
];

export function CvwFAQ() {
  return (
    <div className={"content-width"} data-testid={"cvwFaq"}>
      {cvwQuestionAndAnswerKeyPairs.map((questionAndAnswerKeyPair) => (
        <FaqElement
          faqKeyPair={questionAndAnswerKeyPair}
          key={
            questionAndAnswerKeyPair.questionKey +
            questionAndAnswerKeyPair.answerKey
          }
        />
      ))}
    </div>
  );
}
